import { Container, Typography, Box } from "@mui/material";

const TermsCons = () => {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Términos y Condiciones de Uso de Wubalu
            </Typography>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    1. Información General sobre la Empresa
                </Typography>
                <Typography paragraph>
                    Nombre de la empresa: Wubalu CORP S.L.
                    <br />
                    NIF: B19825207
                    <br />
                    Dirección: Calle de Almagro 25, 2° Izquierda, Madrid, 28010
                    <br />
                    Correo de contacto: hello@wubalu.com
                </Typography>
                <Typography paragraph>
                    Wubalu es una plataforma de marketing para restaurantes que fomenta el boca a boca y las recomendaciones entre amigos a través de redes sociales como Instagram. Actuamos como intermediarios entre los restaurantes y sus clientes finales para ayudar a mejorar la captación de nuevos clientes, aumentar la recurrencia y potenciar la presencia digital de los restaurantes. Nuestros servicios están disponibles únicamente en España, y los envíos de material fuera de la península implicarán un cargo adicional.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    2. Uso de Datos
                </Typography>
                <Typography paragraph>
                    Datos recolectados: Recopilamos datos de contacto, métricas de desempeño y algunos datos de facturación. No almacenamos datos de tarjetas bancarias, ya que estos se gestionan de forma segura a través de Stripe.
                </Typography>
                <Typography paragraph>
                    Protección de datos: Los datos sensibles están encriptados. No compartimos datos con terceros, pero nos reservamos el derecho de utilizar las métricas de desempeño y datos de contacto con fines propios, como publicidad o mejora de la herramienta.
                </Typography>
                <Typography paragraph>
                    Eliminación de datos: Los restaurantes pueden solicitar la eliminación de sus datos enviando un correo a hello@wubalu.com con el concepto "eliminar datos de la cuenta".
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    3. Servicios Ofrecidos
                </Typography>
                <Typography paragraph>
                    Servicios: Wubalu ayuda a los restaurantes a promover su negocio mediante publicidad generada por los clientes, captación de nuevos clientes y aumento de la recurrencia.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Responsabilidades del restaurante:
                </Typography>
                <Typography paragraph>
                    • Cumplir con los términos y condiciones.
                    <br />
                    • Entregar los premios a los clientes que hayan cumplido correctamente con el proceso.
                </Typography>
                <Typography paragraph>
                    Mal uso de la plataforma: Nos reservamos el derecho de suspender cualquier cuenta que incumpla las normativas de Wubalu, tenga problemas éticos o morales, o cualquier otra razón justificable. En estos casos, no se emitirá devolución del dinero.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    4. Premios y Promociones
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Obligaciones del restaurante:
                </Typography>
                <Typography paragraph>
                    • Proporcionar los premios prometidos a los clientes que cumplan con el proceso.
                    <br />
                    • En caso de falta de existencias, ofrecer un premio de valor similar.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Responsabilidad de Wubalu:
                </Typography>
                <Typography paragraph>
                    Wubalu no se hace responsable si el restaurante no cumple con la entrega de los premios. Este incumplimiento podrá derivar en la suspensión inmediata de la cuenta.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    5. Suscripciones y Pagos
                </Typography>
                <Typography paragraph>
                    Métodos de pago: Actualmente, solo aceptamos pagos con tarjeta bancaria a través de Stripe.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Períodos de prueba y promociones:
                </Typography>
                <Typography paragraph>
                    Ofrecemos cupones de descuento en promociones especiales. Estos cupones son personales y no transferibles. Compartirlos puede resultar en la suspensión inmediata de la cuenta.
                </Typography>
                <Typography paragraph>
                    Impagos: La cuenta se suspenderá temporalmente en caso de impago. Si no se resuelve en un plazo de 12 meses, la cuenta se eliminará definitivamente.
                </Typography>
                <Typography paragraph>
                    Cancelación: Las suscripciones se renuevan automáticamente al principio de cada mes. Los restaurantes pueden cancelarlas desde la plataforma o contactando con hello@wubalu.com con 15 días de antelación.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    6. Garantía de los Servicios
                </Typography>
                <Typography paragraph>
                    Wubalu ofrece una garantía específica a sus clientes:
                </Typography>
                <Typography paragraph>
                    Garantía: Se ofrece una garantía de tres meses de suscripción que comienza después del primer mes (ya que este se utiliza para implementar la herramienta). Esta garantía puede ser solicitada dentro de los 20 días naturales posteriores al pago del tercer mes de suscripción. La garantía no cubre los pagos de restaurantes adicionales.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    Condiciones para aplicar la garantía:
                </Typography>
                <Typography paragraph>
                    • No haber alcanzado un total de 30 stories desde el alta en Wubalu.
                    <br />
                    • Solicitar la garantía enviando un email a hello@wubalu.com con el concepto "garantía".
                </Typography>
                <Typography paragraph>
                    Limitaciones: Wubalu no se hace responsable de factores externos como la calidad del contenido publicado por los clientes o problemas derivados de la gestión interna del restaurante.
                </Typography>
                <Typography paragraph>
                    Reembolsos: Si se cumplen las condiciones de la garantía, el reembolso se procesará en un plazo de 45 días laborables.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    7. Limitación de Responsabilidad
                </Typography>
                <Typography paragraph>
                    Wubalu no se hace responsable de:
                    <br />
                    • Errores técnicos o interrupciones del servicio.
                    <br />
                    • Pérdida de datos debido a factores externos.
                </Typography>
                <Typography paragraph>
                    Nuestra responsabilidad financiera se limita al importe pagado por el restaurante en el mes en que ocurra el problema.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    8. Propiedad Intelectual
                </Typography>
                <Typography paragraph>
                    El contenido de Wubalu (marca, diseño, código, etc.) es propiedad exclusiva de Wubalu CORP S.L. y no puede ser utilizado sin autorización previa.
                </Typography>
                <Typography paragraph>
                    Los restaurantes tienen derechos sobre el contenido que generen a través de la plataforma, pudiéndolo utilizar con fines comerciales. Wubalu también se reserva el derecho de usar dicho contenido con fines promocionales o comerciales.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    9. Modificaciones a los Términos y Condiciones
                </Typography>
                <Typography paragraph>
                    Wubalu se reserva el derecho de actualizar estos términos y condiciones en cualquier momento. Las modificaciones serán notificadas a los restaurantes con antelación razonable.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    10. Ley Aplicable y Jurisdicción
                </Typography>
                <Typography paragraph>
                    Estos términos y condiciones se rigen por las leyes españolas. Cualquier disputa será resuelta en los tribunales de Madrid.
                </Typography>
            </Box>

            <Box mb={4}>
                <Typography variant="h5" gutterBottom>
                    11. Contacto
                </Typography>
                <Typography paragraph>
                    Para cualquier duda, consulta o reclamación, los restaurantes pueden ponerse en contacto con nosotros a través del correo hello@wubalu.com.
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsCons;
