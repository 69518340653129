import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import { useGoogleLogin } from "@react-oauth/google";
import { FormProvider, useForm } from "react-hook-form";
import GoogleButton from "../../../components/GoogleButton";
import FormTextInput from "../../../components/TextInput/Form";
import useAuth from "../../../models/auth/useAuth";
import { buildValidationSchema } from "./validations";
import FormCheckbox from "../../../components/Checkbox/Form";

const CreateAccountStep1 = () => {
  const { convertToken, signup, login } = useAuth();

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(buildValidationSchema()),
    defaultValues: {
      name: "",
      password: "",
      repeatPassword: "",
      termscons: false,
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty, isSubmitting },
  } = methods;

  const responseGoogle = (response) => {
    convertToken({
      token: response.access_token,
      backend: "google-oauth2",
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: (error) => console.log("Login Failed:", error),
  });

  const onSubmit = ({ email, password }) => {
    signup({
      email,
      password,
    });
  };

  return (
    <div className="CreateAccount__step">
      <div className="CreateAccount__step__title">
        ¡Empecemos!
        <br /> Regístrate en <span>Wubalu.</span>
      </div>
      <div className="CreateAccount__step__form">
        <FormProvider {...methods} autoComplete={false}>
          <div className="CreateAccount__step__subtitle">Acceso rápido</div>
          <GoogleButton onClick={googleLogin} text="Continuar con Google" />
          <div className="CreateAccount__step__email">
            <div className="CreateAccount__step__subtitle">o con tu email</div>
            <FormTextInput
              control={control}
              name="email"
              placeholder="Tu email"
              required={true}
            />
            <FormTextInput
              control={control}
              name="password"
              type="password"
              placeholder="Contraseña"
              required={true}
            />
            <FormTextInput
              control={control}
              type="password"
              name="repeatPassword"
              placeholder="Repetir contraseña"
              required={true}
            />
          </div>
          <FormCheckbox
            control={control}
            name="termscons"
            label={
              <>
                Para continuar, acepta nuestros{" "}
                <a href="https://www.restaurants.wubalu.com/terms-and-conditions" target="_blank">
                  términos y condiciones
                </a>
              </>
            }
            required={true}
          />
          <Button
            className="CreateAccount__step__submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Registrarme
          </Button>
        </FormProvider>
      </div>
    </div>
  );
};

export default CreateAccountStep1;
