import { useMemo, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import classNames from "classnames";

import useAccounts from "../../../models/accounts/useAccounts";
import useRestaurants from "../../../models/restaurants/useRestaurants";
import starterIcon from "./images/starter.svg";
import proIcon from "./images/pro.svg";
import stripeLogo from "./images/secure_payment.png";

import "./styles.scss";
import { toast } from "react-toastify";

const PaymentMethod = ({ onPaymentSucceed }) => {
  const { account, createSetupIntent, confirmSubscription, setPaymentMethod } =
    useAccounts();
  const { restaurant } = useRestaurants();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(localStorage.getItem("wubalu_plan") || "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    try {
      const { client_secret } = await createSetupIntent();
      if (!client_secret) {
        setErrorMessage("No se pudo inicializar el pago.");
        setLoading(false);
        return;
      }

      // Confirmar el Setup Intent en el frontend
      const { error, setupIntent } = await stripe.confirmCardSetup(
        client_secret,
        {
          payment_method: {
            card: cardElement,
          },
        }
      );


      if (error) {
        setErrorMessage(error.message);
        setLoading(false);
        return;
      }


      if (setupIntent.status === "succeeded") {
        try {
          await setPaymentMethod({
            payment_method: setupIntent.payment_method,
          });

          // Llamar al backend para finalizar la suscripción
          await confirmSubscription({
            plan_name: plan, // Vinculado al cliente
          });
          toast.success("Suscripción creada correctamente.");
          setLoading(false);
          onPaymentSucceed();
        } catch (e) {
          console.log(e);
          toast.error("Error al finalizar la suscripción.");
          setLoading(false);
        }
      }
    } catch (e) {
      toast.error("Error al inicializar el pago.");
      setLoading(false);
    }
  };

  return (
    <div className="PaymentMethod">
      <div className="PaymentInfoModal__title">Selecciona el plan <span>que más se adapte a tus necesidades.</span></div>
      {account.discount_code && (
        <div className="PaymentMethod__discount">
          Código descuento aplicado.
        </div>
      )}
      <div className="PaymentMethod__plans">
        <div className={classNames("PaymentMethod__plan", { "PaymentMethod__plan--active": plan === "starter" })} onClick={() => setPlan("starter")}>
          <div className="PaymentMethod__plan__image">
            <img src={starterIcon} alt="Starter" />
          </div>
          <div className="PaymentMethod__plan__content">
            <div className="PaymentMethod__plan__description">
              Perfecto para empezar a ser tendencia
            </div>
            <div className="PaymentMethod__plan__price">
              <span className="amount">{account.discount_code ? 250 - account.starter_discount : 250}€ <span className="amount__vat">+ iva</span></span>
              <span className="terms">Primer mes por 100€ + iva</span>
            </div>
          </div>
        </div>
        <div className={classNames("PaymentMethod__plan", { "PaymentMethod__plan--active": plan === "pro" })} onClick={() => setPlan("pro")}>
          <div className="PaymentMethod__plan__image PaymentMethod__plan--pro__image">
            <img src={proIcon} alt="Pro" />
          </div>
          <div className="PaymentMethod__plan__content">
            <div className="PaymentMethod__plan__description">
              El plan definitivo para destacar y maximizar tus resultados.
            </div>
            <div className="PaymentMethod__plan__price">
              <span className="amount">{account.discount_code ? 500 - account.pro_discount : 500}€ <span className="amount__vat">+ iva</span></span>
              <span className="terms">Primer mes por 200€ + iva</span>
            </div>
          </div>
        </div>
      </div>

      <div className="PaymentMethod__read-more">
        <a href="https://restaurants.wubalu.com/pricing" target="_blank">
          Consulta detalle de precios
        </a>
      </div>
      <div className="PaymentInfoModal__section__title">Método de pago</div>
      <div className="PaymentMethod__image">
        <img src={stripeLogo} alt="Stripe" />
      </div>
      <div className="PaymentMethod__card">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
      </div>
      {errorMessage && (
        <div className="PaymentMethod__error">{errorMessage}</div>
      )}
      <div>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!stripe || loading}
        >
          Guardar y finalizar
        </Button>
      </div>
    </div>
  );
};

export default PaymentMethod;
