import { Checkbox, FormControlLabel } from "@mui/material";

const CheckboxInput = ({ onChange, name, label, required }) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <FormControlLabel
      className="Checkbox"
      control={
        <Checkbox />
      }
      label={label}
      onChange={handleChange}
      name={name}
    />
  );
};

export default CheckboxInput;
