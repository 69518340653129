import { Controller } from "react-hook-form";
import CheckboxInput from "./index";

const FormCheckbox = ({ control, name, label, required, onChange }) => {
  const handleChange = ({ name, value, onFieldChange }) => {
    onFieldChange(value);
    onChange && onChange(name, value);
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <CheckboxInput
          label={label ?? ""}
          required={required}
          name={field.name}
          value={field.value ?? false}
          onChange={(value) =>
            handleChange({
              value,
              name: field.name,
              onFieldChange: field.onChange,
            })
          }
        />
      )}
    />
  );
};

export default FormCheckbox;
